
.logo{
    width: 100px;
}
.header{
    background-color: #131314;
    padding: 10px 0;
    color: #fff;
}
.arrow {
    transform: rotate(-20deg);
    font-size: 14px;
} 

.nav-links::after{
    background-color: #f05a43;
    position: absolute;
    content: '';
    width: 0;
    height: 4px;
    left: 0;
    bottom: 0;
    transition: 1s ease-in-out;
    margin: 0 10%;
}
.nav-links:hover::after{
    width: 50%;
    margin: 0 25%;
}

.hamburger {
    cursor: pointer;
  }
  
  .hamburger input {
    display: none;
  }
  
  .hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 2em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line {
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line-top-bottom {
    stroke-dasharray: 12 63;
  }
  
  .hamburger input:checked + svg {
    transform: rotate(-45deg);
  }
  
  .hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
  }

 
  