:root {
  --primary-bg: #070709;
  --btn-bg: #f05a43;
  --btn-border: #aeaeae;

}

@font-face {
  font-family: neo-med;
  src: url(./assets/fonts/NeueHaasDisplayMediu.ttf);
}

@font-face {
  font-family: neo-light;
  src: url(./assets/fonts/NeueHaasDisplayLight.ttf);
}

@font-face {
  font-family: neo-bold;
  src: url(./assets/fonts/NeueHaasDisplayBold.ttf);
}

@font-face {
  font-family: garpic;
  src: url(./assets/fonts/GraphikCondensed-Regular-Trial.otf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "neo-med" serif;
}

.nav-button {
  background-color: var(--btn-bg);
  padding: 5px 10px;
  font-size: 18px;
  position: relative;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.nav-button::after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--btn-border);
  background-color: transparent;
  z-index: -1;
}

.nav-button:hover+.nav-button::after {
  top: 0;
  left: 0;
}


.nav-links {
  position: relative;
  padding: 15px 20px !important;
  color: #fff !important;
  font-family: 'neo-med' serif;
  font-size: 18px !important;

}



/* .nav-button::after {
  position: absolute;
  content: '';
  border: 1px solid var(--btn-border);
  width: 100%;
  height: 100%;
  bottom: -5px;
  left: 5px;
  z-index: -1;
} */

.hero-sec {
  background-color: var(--primary-bg);
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  gap: 20px;
}

.hero-sec::after {
  position: absolute;
  content: '';
  background: linear-gradient(90deg,
      rgba(247, 147, 38, 1) 0%,
      rgba(240, 98, 61, 1) 20%,
      rgba(239, 70, 79, 1) 40%,
      rgba(238, 60, 86, 1) 60%,
      rgba(236, 27, 110, 1) 80%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(80px);
  width: 80%;
  height: 50px;
  margin: 0 10%;
  bottom: 0;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  overflow: hidden;

}

.hero-sec h1 {
  width: 80%;
  text-align: center;
  font-size: 50px;
  font-family: "neo-bold" serif !important;
}

.hero-sec h3 {
  font-weight: 400;
  font-size: 40px;
}

.text-clr {
  background: linear-gradient(90deg,
      rgba(247, 147, 38, 1) 0%,
      rgba(240, 98, 61, 1) 20%,
      rgba(239, 70, 79, 1) 40%,
      rgba(238, 60, 86, 1) 60%,
      rgba(236, 27, 110, 1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.read-more {
  position: relative;
  width: fit-content;
}

.read-more::after {
  position: absolute;
  content: '';
  bottom: -10px;
  height: 1px;
  width: 80%;
  background-color: #fff;
  left: 0;
}



/* =================ousr vesion============= */
.our-vesion {
  background-color: var(--primary-bg);
  height: 100vh;
  padding: 30px 0;
  position: relative;
}

.center-title {
  color: var(--btn-border);
  position: relative;
  font-family: "neo-light" serif !important;
  width: fit-content;
  padding: 5px 0;
  font-size: 30px;
  font-weight: 200;
}

.center-title::after {
  position: absolute;
  content: '';
  bottom: 0;
  width: 60%;
  margin: 0 20%;
  height: 1px;
  left: 0;
  background-color: var(--btn-border);
}

.our-vision-content {
  color: #fff;
  height: 80vh;
  display: flex;
  align-items: self-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.our-vision-content h2 {
  width: 50%;
  font-size: 50px;
}

.our-vision-content p {
  width: 65%;
  line-height: 35px;
}

.arrow-rot {
  transform: rotate(-30deg) !important;
  font-weight: 300;
  font-size: 16px;
}

.vison-ele {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.ele {
  width: 200px;
  height: 200px;
  animation: rotate 15s infinite;
  transition: 5s ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ============experince sec==== */

.experince-sec {
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.exp-content {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  height: 80%;
  width: 100%;
}

.exp-text {
  width: 50%;
  color: #1a1616;
  padding: 50px 0 0 100px;
}

.exp-text h2 {
  width: 70%;
  font-size: 40px;
  padding-bottom: 30px;
}

.exp-text p {
  width: 70%;
}

.exp-img {
  width: 50%;
  position: relative;
  right: 0;
}

.exp-img img {
  height: 70vh;
  width: 100%;
  object-fit: contain;
  position: absolute;
  right: 0;
}

.exp-ele {
  position: absolute;
  bottom: 30px;
  left: -10px;
}


/* ===============who we seec ===========*/
.who-we-sec {
  height: 100vh;
  background-image: url(./assets/images/who-we.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
  position: relative;
}

.who {
  color: #fff !important;
}

.who-content {
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 80vh;
}

.center-who-content {
  width: 80%;
  font-size: 30px;
  text-align: center;
}


/* ========virtual sec============ */

.virtual-sec {
  height: 100vh;
  background-color: #fef9f8;
  padding: 30px 0;
  position: relative;
}

.virtual-mark-text {
  position: relative;
  font-size: 45px;
  width: 70%;
  margin: 0 15%;
  font-weight: 700;
  color: #000;
  margin-top: 60px;
}

.child1 {
  position: absolute;
  top: 0;
  left: -60px;
  width: 35px;
  height: 35px;
}

.child2 {
  position: absolute;
  top: -40px;
  left: -120px;
  width: 35px;
  height: 35px;
}

.w-bg {
  position: relative;
  width: fit-content;
}

.w-bg::after {
  position: absolute;
  content: '';
  bottom: -10px;
  height: 1px;
  width: 80%;
  background-color: #100A0B;
  left: 0;
}

.virtual-img {
  position: relative;
  /* bottom: -100px; */
  left: 0;
  background-color: var(--primary-bg);
  width: 100%;
  height: 100%;
}

/* =======marquee ============= */
.marquee {
  width: 100%;
  /* Full width */
  overflow: hidden;
  /* Hide overflowing content */
  white-space: nowrap;
  /* Prevent text wrapping */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

.marquee__inner {
  display: inline-block;
  padding-left: 100%;
  /* Start position */
  animation: marquee 10s linear infinite;
  /* Animation */
}

.marquee__inner span {
  display: inline-block;
  padding-right: 100%;
  /* Extra padding to allow seamless scrolling */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* ============tour======= */
.tour {
  background-color: var(--primary-bg);
  height: auto;
  padding: 30px 0;
  color: #fff;
}

.marquee-text {
  margin-top: 20px;
}

.marquee-text span {
  font-size: 80px;
  color: #fff !important;
  gap: 20px;
  font-weight: 700;
  opacity: 0.3;
  margin: 0 40px;
}

.tour-top-sec {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 30px;
}

.tour-top-sec h3 {
  font-weight: 300;
}

.art-topic {
  width: 50%;
  text-align: center;
  gap: 20px;
  flex-direction: column;
  display: flex;

}

.art-topic h2 {
  font-size: 45px;
  font-weight: 700;
}

.art-topic p {
  color: #8A94A6;
  line-height: 30px;
  font-size: 18px;
}

.img-con {
  padding-bottom: 80px;
}

.cartimg {
  transition: 1s ease-in-out;
}

.cartimg:hover {
  margin-top: -30px;
  cursor: url('./assets/images/Link.svg'), auto;
}


/* =====marquee sec========== */
.marquee-sec {
  height: auto;
  padding: 50px 0;
}

.marquee-center {
  font-weight: 400;
  color: #000;

}

.marq-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.emabrk-sec {
  background-color: var(--primary-bg);
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0;
}

.emabrk-sec h2 {
  width: 55%;
  font-size: 40px;
  color: #fff;
  text-align: center;
  font-family: "neo-bold" serif !important;
  font-weight: 700;
}

.exp-service-sec {
  height: auto;
  /* padding: 40px 0; */
  position: relative;
}

.vr-img {
  position: relative;
  width: 100%;
}

.vr-img img {
  width: 50%;
  height: 100%;
  object-fit: contain;
  margin: 0 25%;
  position: relative;
  bottom: -100px;

}


.bottom-sec {
  background-image: url(./assets/images/vec-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative !important;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.bottom-sec::after {
  position: absolute;
  content: '';
  bottom: 0;
  background-color: #000;
  width: 100%;
  height: 50px;
}

.bottom-sec h2 {
  font-size: 55px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
}

.hover-sec {
  background-color: #000;
  padding: 30px 0;
  height: auto;
  position: relative !important;
  /* top: 100vh; */
  color: #fff;

}

.hov-con {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.text-hov {
  position: relative;
  z-index: 2;
  width: 100%;
  display: inline-block;
  width: fit-content;
}

.text-hov h2 {
  white-space: none;
  font-weight: 400;
}

.text-container h4 {
  color: #39393A;
}

.text-hov img {
  position: absolute;
  width: 300px;
  height: 400px;
  object-fit: cover;
  top: -200px;
  transition: transform 0.1s ease-out;
  z-index: -1;
  opacity: 0;
}

.text-container:hover+.img-hov {
  opacity: 1;
}

.con-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
}

.box-start {
  align-self: flex-start;
  /* Aligns this box to the start horizontally */
}

.box-center {
  align-self: center;
  /* Aligns this box to the center horizontally */
}

.box-end {
  align-self: flex-end;
  /* Aligns this box to the end horizontally */
}

.con-two {
  padding-bottom: 30px;
}

.num-hov,
.text-list-hov {
  font-family: 'garpic' serif !important;
}

.bottom-box {
  position: relative;
  float: right;
  top: -80px;
}

.bottom-box img {
  width: 500px;
  position: relative;
  float: right;

}

.testimonial-sec {
  background-color: #fff;
  height: auto;
  position: relative;
  padding: 80px 0;
}

.testi-bor-text {
  background-color: #F1F2F4;
  padding: 5px 8px;
  border-radius: 20px;
  width: fit-content;
}

.testi-titl {
  font-size: 50px;
  font-weight: 400;
}

.box-testi-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}

.box-testi {
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.2), -1px -1px 3px rgb(0, 0, 0, 0.2);
  padding: 10px;
  height: 200px;
  width: 32%;
  margin: 10px 0;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
}

.designation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}





.latest-article-sec {
  position: relative;
  background-color: #000;
  padding: 50px 0;
  height: auto;
  color: #fff;
  height: auto;
}

.bg-article {
  position: absolute;
  top: 0px;
  width: 200px;
  right: 0;
}

.f-con {
  display: flex;
  align-items: center;
  width: 100%;
}

.f-1 img,
.f-5 img {
  height: 300px;
}

.f-2 img,
.f-4 img {
  height: 350px;
}

.f-3 img {
  height: 400px;
}

.f-1,
.f-2,
.f-3,
.f-4,
.f-5 {
  flex: 1;
  transition: 1s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 500px;
  margin: 20px 50px;
  overflow: hidden;
}

.f-1:hover,
.f-2:hover,
.f-3:hover,
.f-4:hover,
.f-5:hover {
  flex: 5;
  cursor: url('./assets/images/Link.svg'), auto;
}

.f-1:hover img,
.f-2:hover img,
.f-3:hover img,
.f-4:hover img,
.f-5:hover img {
  height: 300px;
  object-fit: cover;
}

.flex-text {
  color: #fff;
  display: none;
  transition-delay: 1ms;
  height: 200px;
  text-align: left;
}
.flex-text p{
  font-size: 16px;
}

.f:hover .flex-text {
  display: block;
}

footer{
  background-color: #000;
  height: auto;
  padding: 20px 0;
  color: #fff;
}
.foot-ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
.foot-ul h2{
  font-size: 25px;
  font-weight: 300;
}
.foot-ul li{
  margin: 20px 0;
}
.foot-logo{
  width: 150px;
}

.social{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  
}
.social h2{
  font-size: 25px;
  font-weight: 300;
}
.social-link{
display: flex;
align-items: center;
gap: 20px;
}
.footer-bottom{
  color: #F9F9F9;
}